<script lang="ts">
	import { Icon } from '@smui/icon-button';
	import { Svg } from '@smui/common/elements';

	import { mdiHelpRhombus } from '@mdi/js';
	export let mdiIcon = mdiHelpRhombus;
	export let slot = '';
	export let viewBox = '0 0 24 24';
	export let style = '';
	export let title = '';
	export let color = 'currentColor';
</script>

<Icon component={Svg} {viewBox} {slot} {style} {title}>
	<path fill={color} d={mdiIcon} />
</Icon>
